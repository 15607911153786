@font-face {
  font-family: 'AlbertusMTPro-Light';
  src: local('AlbertusMTPro-Light'),
    url('./Albertus/AlbertusMTPro-Light.otf') format('opentype');
}

@font-face {
  font-family: 'ABCFavorit-Bold';
  src: local('ABCFavorit-Bold'),
    url('./Favorit/ABCFavorit-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'ABCFavorit-BoldItalic';
  src: local('ABCFavorit-BoldItalic'),
    url('./Favorit/ABCFavorit-BoldItalic.otf') format('opentype');
}
@font-face {
  font-family: 'ABCFavorit-Book';
  src: local('ABCFavorit-Book'),
    url('./Favorit/ABCFavorit-Book.otf') format('opentype');
}
@font-face {
  font-family: 'ABCFavorit-BookItalic';
  src: local('ABCFavorit-BookItalic'),
    url('./Favorit/ABCFavorit-BookItalic.otf') format('opentype');
}
@font-face {
  font-family: 'ABCFavorit-RegularItalic';
  src: local('ABCFavorit-RegularItalic'),
    url('./Favorit/ABCFavorit-RegularItalic.otf') format('opentype');
}
@font-face {
  font-family: 'ABCFavorit-Light';
  src: local('ABCFavorit-Light'),
    url('./Favorit/ABCFavorit-Light.otf') format('opentype');
}
@font-face {
  font-family: 'ABCFavorit-LightItalic';
  src: local('ABCFavorit-LightItalic'),
    url('./Favorit/ABCFavorit-LightItalic.otf') format('opentype');
}
@font-face {
  font-family: 'ABCFavorit-Medium';
  src: local('ABCFavorit-Medium'),
    url('./Favorit/ABCFavorit-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'ABCFavorit-MediumItalic';
  src: local('ABCFavorit-MediumItalic'),
    url('./Favorit/ABCFavorit-MediumItalic.otf') format('opentype');
}
@font-face {
  font-family: 'ABCFavorit-Regular';
  src: local('ABCFavorit-Regular'),
    url('./Favorit/ABCFavorit-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'OptimaLTPro-Roman';
  src: local('OptimaLTPro-Roman'),
    url('./Optima/OptimaLTPro-Roman.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-Italic';
  src: local('OptimaLTPro-Italic'),
    url('./Optima/OptimaLTPro-Italic.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-Medium';
  src: local('OptimaLTPro-Medium'),
    url('./Optima/OptimaLTPro-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-MediumItalic';
  src: local('OptimaLTPro-MediumItalic'),
    url('./Optima/OptimaLTPro-MediumItalic.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-DemiBold';
  src: local('OptimaLTPro-DemiBold'),
    url('./Optima/OptimaLTPro-DemiBold.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-DemiBoldItalic';
  src: local('OptimaLTPro-DemiBoldItalic'),
    url('./Optima/OptimaLTPro-DemiBoldItalic.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-Bold';
  src: local('OptimaLTPro-Bold'),
    url('./Optima/OptimaLTPro-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-BoldItalic';
  src: local('OptimaLTPro-BoldItalic'),
    url('./Optima/OptimaLTPro-BoldItalic.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-Black';
  src: local('OptimaLTPro-Black'),
    url('./Optima/OptimaLTPro-Black.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-BlackItalic';
  src: local('OptimaLTPro-BlackItalic'),
    url('./Optima/OptimaLTPro-BlackItalic.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-ExtraBlack';
  src: local('OptimaLTPro-ExtraBlack'),
    url('./Optima/OptimaLTPro-ExtraBlack.otf') format('opentype');
}
@font-face {
  font-family: 'OptimaLTPro-ExtraBlackItalic';
  src: local('OptimaLTPro-ExtraBlackItalic'),
    url('./Optima/OptimaLTPro-ExtraBlackItalic.otf') format('opentype');
}
/* Gulzar */
@font-face {
  font-family: 'Gulzar-Regular';
  src: local('Gulzar-Regular'),
    url('./Gulzar/Gulzar-Regular.ttf') format('truetype');
}
/* NotoNaskh */
@font-face {
  font-family: 'NotoNaskhArabic-Regular';
  src: local('NotoNaskhArabic-Regular'),
    url('./NotoNaskh/NotoNaskhArabic-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoNaskhArabic-Medium';
  src: local('NotoNaskhArabic-Medium'),
    url('./NotoNaskh/NotoNaskhArabic-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoNaskhArabic-SemiBold';
  src: local('NotoNaskhArabic-SemiBold'),
    url('./NotoNaskh/NotoNaskhArabic-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoNaskhArabic-Bold';
  src: local('NotoNaskhArabic-Bold'),
    url('./NotoNaskh/NotoNaskhArabic-Bold.ttf') format('truetype');
}

/* NotoSansArabic */
@font-face {
  font-family: 'NotoSansArabic-Regular';
  src: local('NotoSansArabic-Regular'),
    url('./NotoSans/NotoSansArabic-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSansArabic-Medium';
  src: local('NotoSansArabic-Medium'),
    url('./NotoSans/NotoSansArabic-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'NotoSansArabic-SemiBold';
  src: local('NotoSansArabic-SemiBold'),
    url('./NotoSans/NotoSansArabic-SemiBold.ttf') format('truetype');
}

/* CYRILLIC */

@font-face {
  font-family: 'ArtifexHandCF-ExtraLight';
  src: local('ArtifexHandCF-ExtraLight'),
    url('./ArtifexHandCF/ArtifexHandCF-ExtraLight.otf') format('opentype');
}

/* OpenSans-Regular (ttf) */

@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'),
    url('./OpenSans/OpenSans-Regular.ttf') format('truetype');
}

/* OpenSans-Italic */

@font-face {
  font-family: 'OpenSans-Italic';
  src: local('OpenSans-Italic'),
    url('./OpenSans/OpenSans-Italic.ttf') format('truetype');
}

/* OpenSans-SemiBold */

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'),
    url('./OpenSans/OpenSans-SemiBold.ttf') format('truetype');
}

/* OpenSans-SemiBoldItalic */

@font-face {
  font-family: 'OpenSans-SemiBoldItalic';
  src: local('OpenSans-SemiBoldItalic'),
    url('./OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
}
