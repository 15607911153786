.iubenda_embed_policy h1,
.iubenda_embed_policy h2,
.iubenda_embed_policy h3,
.iubenda_embed_policy h4,
.iubenda_embed_policy h5 {
  text-transform: uppercase;
  line-height: 1.25em;
  text-align: center;
}

.iubenda_legal_document h1 {
  text-align: center;
}

.one_line_col h2 {
  font-variant: all-small-caps !important;
  /* text-align: center; */
}
ul.for_boxes {
  padding: 0;
}
ul.for_boxes div.iconed ul h3 {
  margin-bottom: 0;
}
.simple_pp {
  border: 1px solid black;
  border-radius: 4px;
  margin-top: 1.5rem;
  padding: 20px;
}

.dark .simple_pp {
  border: 1px solid white;
  border-radius: 4px;
  margin-top: 1.5rem;
  padding: 20px;
}
#iubenda_policy ul.for_boxes,
#iubenda_policy ul.for_boxes > li,
#iubenda_policy ul.unstyled,
#iubenda_policy ul.unstyled > li {
  list-style: none;
}

a {
  color: black;
}

.dark a {
  color: white;
}

summary {
  list-style-position: outside;
  margin-left: 1em;
}
