input[type='file'] {
  display: none;
}

.upload-page-btn {
  border: none;
  background-color: black;
  color: white;
  border-radius: 50px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'ABCFavorit-Book';
  text-transform: uppercase;
  display: inline-block;
  font-size: 18px;
}

.upload-page-btn.dark {
  background-color: white;
  color: black;
}
