body {
  overflow: scroll !important;
}

#symbol-letters {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
}
